import { NEW_JWT } from '@santsg/config'
import { fetchUserPermissions } from '@santsg/api/authentication/permissions'

NEW_JWT.subscribe(async () => {
  try {
    await fetchUserPermissions()
  } catch (e) {
    console.log(e)
  }

  window.location.href = '/dashboard'
})
