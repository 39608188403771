import { defineRoute } from '@santsg/router'

import HomePage from '@/_main_/app/page'
import { dayjs, formatDate } from '@santsg/common'

const routes = {
  /* ======================================================
   * HOME & DASHBOARD
   * ======================================================
   */

  home: defineRoute({
    path: '/',
    component: HomePage,
  }),

  dashboard: defineRoute({
    path: '/dashboard',
    importer: () => import('@/_main_/app/dashboard/page'),
  }),

  /* ======================================================
   *  BOOKINGS
   * ======================================================
   */

  bookingImport: defineRoute({
    path: '/booking/booking-import',
    importer: () => import('@/_main_/app/booking-import/page'),
  }),

  mapping: defineRoute({
    path: '/booking/mapping',
    importer: () => import('@/_main_/app/mapping/layout'),
  }),

  mappingBuyer: defineRoute({
    path: '/booking/mapping/buyer',
    importer: () => import('@/_main_/app/mapping/buyer/page'),
  }),

  // @TODO Move to transfer app
  mappingTransferType: defineRoute({
    path: '/booking/mapping/transfer',
    importer: () => import('@/_main_/app/mapping/transfers/page'),
  }),

  // @TODO Move to hotel app
  mappingHotel: defineRoute({
    path: '/booking/mapping/hotel',
    importer: () => import('@/_main_/app/mapping/hotel/page'),
  }),

  mappingFromToLocation: defineRoute({
    path: '/booking/mapping/from-to-location',
    importer: () => import('@/_main_/app/mapping/location/page'),
  }),

  /* ======================================================
   * SERVICES
   * ======================================================
   */

  /* ======================================================
   * ACTORS
   * ======================================================
   */

  buyers: defineRoute({
    path: '/actors/buyers',
    importer: () => import('@/_main_/app/buyers/page'),
  }),

  buyer: defineRoute({
    path: '/actors/buyers/:id',
    importer: () => import('@/_main_/app/buyers/[id]/layout'),
  }),

  buyerDetails: defineRoute({
    path: '/actors/buyers/:id/details',
    importer: () => import('@/_main_/app/buyers/[id]/details/page'),
  }),

  buyerUsers: defineRoute({
    path: '/actors/buyers/:id/users',
    importer: () => import('@/_main_/app/buyers/[id]/users/page'),
  }),

  buyerInviteUsers: defineRoute({
    path: '/actors/buyers/:id/invite-users',
    importer: () => import('@/_main_/app/buyers/[id]/invite-users/page'),
  }),

  suppliers: defineRoute({
    path: '/actors/suppliers',
    importer: () => import('@/_main_/app/suppliers/page'),
  }),

  supplier: defineRoute({
    path: '/actors/suppliers/:id',
    importer: () => import('@/_main_/app/suppliers/[id]/layout'),
  }),

  supplierDetails: defineRoute({
    path: '/actors/suppliers/:id/details',
    importer: () => import('@/_main_/app/suppliers/[id]/details/page'),
  }),

  supplierPrices: defineRoute({
    path: '/actors/suppliers/:id/prices',
    importer: () => import('@/_main_/app/suppliers/[id]/prices/page'),
  }),

  companyUsers: defineRoute({
    path: '/actors/companies/:id/company-users',
    importer: () => import('@/_main_/app/companies/[id]/users/page'),
  }),

  companyInvitedUsers: defineRoute({
    path: '/actors/companies/:id/invited-users',
    importer: () => import('@/_main_/app/companies/[id]/invited-users/page'),
  }),

  companies: defineRoute({
    path: '/actors/companies',
    importer: () => import('@/_main_/app/companies/page'),
  }),

  company: defineRoute({
    path: '/actors/companies/:id',
    importer: () => import('@/_main_/app/companies/[id]/layout'),
  }),

  companyDetails: defineRoute({
    path: '/actors/companies/:id/details',
    importer: () => import('@/_main_/app/companies/[id]/details/page'),
  }),

  users: defineRoute({
    path: '/actors/users',
    importer: () => import('@/_main_/app/users/page'),
  }),

  invitedUsers: defineRoute({
    path: '/actors/invited-users',
    importer: () => import('@/_main_/app/invited-users/page'),
  }),

  organizations: defineRoute({
    path: '/actors/organizations',
    importer: () => import('@/_main_/app/organizations/page'),
  }),

  organization: defineRoute({
    path: '/actors/organizations/:id',
    importer: () => import('@/_main_/app/organizations/[id]/layout'),
  }),

  organizationDetails: defineRoute({
    path: '/actors/organizations/:id/details',
    importer: () => import('@/_main_/app/organizations/[id]/details/page'),
  }),

  organizationUsers: defineRoute({
    path: '/actors/organizations/:id/users',
    importer: () => import('@/_main_/app/organizations/[id]/users/page'),
  }),

  organizationInviteUsers: defineRoute({
    path: '/actors/organizations/:id/invite-users',
    importer: () => import('@/_main_/app/organizations/[id]/invite-users/page'),
  }),

  /* ======================================================
   * MASTER DATA
   * ======================================================
   */

  /* ======================================================
   * MASTER DATA > COMMON USED
   * ======================================================
   */

  markets: defineRoute({
    path: '/master-data/common-used/markets',
    importer: () => import('@/_main_/app/markets/page'),
  }),

  /* ======================================================
   * ACCOUNTING
   * ======================================================
   */

  companyAccounts: defineRoute({
    path: '/accounting/company-accounts',
    importer: () => import('@/_main_/app/company-accounts/page'),
  }),

  customCurrencyRates: defineRoute({
    path: '/accounting/custom-currency-rates',
    importer: () => import('@/_main_/app/custom-currency-rates/page'),
  }),

  providerRates: defineRoute({
    path: '/accounting/provider-rates',
    importer: () => import('@/_main_/app/provider-rates/page'),
  }),

  guideFinancial: defineRoute({
    path: '/accounting/guide-financial',
    importer: () => import('@/_main_/app/guide-financial/page'),
  }),

  supplierFinancial: defineRoute({
    initialQueryParams: () => {
      const today = dayjs()
      const lastThirtyDays = today.subtract(30, 'day')

      return {
        saleDateFrom: formatDate(lastThirtyDays, 'YYYY-MM-DDT00:00:00[Z]'),
        saleDateTo: formatDate(today, 'YYYY-MM-DDT00:00:00[Z]'),
      }
    },
    path: '/accounting/supplier-financial',
    importer: () => import('@/_main_/app/supplier-financial/page'),
  }),

  supplierFinancialDetail: defineRoute({
    path: '/accounting/supplier-financial/:id',
    importer: () => import('@/_main_/app/supplier-financial/[id]/page'),
  }),

  supplierFinancialDetailBookings: defineRoute({
    path: '/accounting/supplier-financial/:id/bookings',
    importer: () => import('@/_main_/app/supplier-financial/[id]/bookings-tab/page'),
  }),

  supplierFinancialPaymentDetails: defineRoute({
    path: '/accounting/supplier-financial/:id/payment-details/:paymentId',
    importer: () => import('@/_main_/app/supplier-financial/[id]/payment-details/[paymentId]/page'),
  }),

  supplierFinancialInvoiceDetails: defineRoute({
    path: '/accounting/supplier-financial/:id/invoice-details/:invoiceId',
    importer: () => import('@/_main_/app/supplier-financial/[id]/invoice-details/[invoiceId]/page'),
  }),

  supplierFinancialDetailInvoices: defineRoute({
    path: '/accounting/supplier-financial/:id/invoices',
    importer: () => import('@/_main_/app/supplier-financial/[id]/invoices-tab/page'),
  }),

  supplierFinancialDetailPayments: defineRoute({
    path: '/accounting/supplier-financial/:id/payments',
    importer: () => import('@/_main_/app/supplier-financial/[id]/payments-tab/page'),
  }),

  guideFinancialDetail: defineRoute({
    path: '/accounting/guide-financial/:id',
    importer: () => import('@/_main_/app/guide-financial/[id]/layout'),
  }),

  guideFinancialDetailCollection: defineRoute({
    path: '/accounting/guide-financial/:id/collection',
    importer: () => import('@/_main_/app/guide-financial/[id]/guideCollectionTab/page'),
  }),

  guideFinancialDetailCommission: defineRoute({
    path: '/accounting/guide-financial/:id/commission',
    importer: () => import('@/_main_/app/guide-financial/[id]/guideCommissionTab/page'),
  }),

  guideFinancialDetailFinancials: defineRoute({
    path: '/accounting/guide-financial/:id/financials',
    importer: () => import('@/_main_/app/guide-financial/[id]/financialsTab/page'),
  }),

  /* ======================================================
   * SETTING
   * ======================================================
   */

  settings: defineRoute({
    path: '/settings',
    importer: () => import('@/_main_/app/organization-settings/page'),
  }),

  bookingSetting: defineRoute({
    path: '/settings/booking-setting',
    importer: () => import('@/_main_/app/organization-settings/booking-settings/page'),
  }),

  currencySetting: defineRoute({
    path: '/settings/currency-setting',
    importer: () => import('@/_main_/app/organization-settings/currency-settings/page'),
  }),

  localizationSetting: defineRoute({
    path: '/settings/localization-setting',
    importer: () => import('@/_main_/app/organization-settings/localization-settings/page'),
  }),

  integrationSetting: defineRoute({
    path: '/settings/integration-setting',
    importer: () => import('@/_main_/app/organization-settings/integration-settings/page'),
  }),

  smtpSetting: defineRoute({
    path: '/settings/smtp-setting',
    importer: () => import('@/_main_/app/organization-settings/smtp-settings/page'),
  }),

  notificationSetting: defineRoute({
    path: '/settings/notification-setting',
    importer: () => import('@/_main_/app/organization-settings/notification-settings/page'),
  }),

  dateTimeSetting: defineRoute({
    path: '/settings/date-time',
    importer: () => import('@/_main_/app/organization-settings/date-time/page'),
  }),

  roles: defineRoute({
    path: '/setting/roles-permissions',
    importer: () => import('@/_main_/app/roles-permissions/page'),
  }),

  rolePermissions: defineRoute({
    path: '/setting/roles-permissions/:id',
    importer: () => import('@/_main_/app/roles-permissions/[id]/permissions/page'),
  }),

  localizations: defineRoute({
    path: '/setting/localizations',
    importer: () => import('@/_main_/app/localizations/page'),
  }),

  /* ======================================================
   * OTHER
   * ======================================================
   */

  userProfile: defineRoute({
    path: '/user/edit',
    importer: () => import('@/_main_/app/user-profile/page'),
  }),

  organizationProfile: defineRoute({
    path: '/company/edit',
    importer: () => import('@/_main_/app/organization-profile/page'),
  }),
}

export type RouteNameType = keyof typeof routes

Object.keys(routes).forEach((name) => {
  routes[name as RouteNameType].name = name
})

export { routes }
