import type { RouteConfigType } from '@santsg/router'
import { routes as defs } from './routes.def'

export const routes: RouteConfigType[] = [
  defs.vehicleOperations,
  defs.vehicleManagement,
  defs.transferPriceSearch,
  defs.transfers,
  {
    ...defs.transfer,
    children: [
      defs.transferDetails,
      defs.transferMedia,
      defs.transferPrices,
      defs.transferAncillaryPrices,
      defs.transferRestrictions,
      defs.transferWarningMessages,
    ],
  },
  defs.transferAncillaries,
  defs.operationalRegionGroups,
  defs.transferCostItems,
  defs.transferTypes,
  defs.vehicleTypes,
  defs.drivers,
  defs.vehicles,
  defs.transferIntroductionHeaders,
]
