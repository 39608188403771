import { registerMainMenuItems, useSetInitialMainMenu } from '@santsg/octopus.shared'

import useMainAppMenuItems from '@/_main_/menu.main'
import useExcursionAppMenuItems from '@/_excursion_/menu.main'
import useTransferAppMenuItems from '@/_transfer_/menu.main'
import useHotelAppMenuItems from '@/_hotel_/menu.main'
import useBookingAppMenuItems from '@/_booking_/menu.main'
import useFlightAppMenuItems from '@/_flight_/menu.main'

export function useRegisterMainMenu() {
  const mainMenuItems = useMainAppMenuItems()
  const excursionMenuItems = useExcursionAppMenuItems()
  const transferMenuItems = useTransferAppMenuItems()
  const hotelMenuItems = useHotelAppMenuItems()
  const bookingMenuItems = useBookingAppMenuItems()
  const flightMenuItems = useFlightAppMenuItems()

  useSetInitialMainMenu(() => {
    registerMainMenuItems(mainMenuItems)
    registerMainMenuItems(excursionMenuItems)
    registerMainMenuItems(transferMenuItems)
    registerMainMenuItems(hotelMenuItems)
    registerMainMenuItems(bookingMenuItems)
    registerMainMenuItems(flightMenuItems)
  })
}
