import React from 'react'
import ReactDOM from 'react-dom/client'

import { AppLoader, createShell, useCommonTranslations } from '@santsg/octopus.shared'
import { useTranslationNs } from '@santsg/i18n'
import { getLoggedInUser } from '@santsg/common'

import App from './App'
import './configure.events'

import '@santsg/octopus.shared/dist/style.css'
import '@santsg/octopus.shared/dist/style.tailwind.css'
import './index.css'

import { type RouteNameType, routes as routeDefinitions } from './routes.def'
import { authRoutes, userRoutes } from './routes.config'

export default async function renderOctopus() {
  const root = ReactDOM.createRoot(document.getElementById('root')!)

  root.render(
    <React.StrictMode>
      <AppLoader />
    </React.StrictMode>,
  )

  const shell = await createShell<RouteNameType>({
    routeDefinitions,
    authRoutes,
    userRoutes,
  })

  globalThis.useCommonTranslations = useCommonTranslations
  globalThis.useTranslationNs = useTranslationNs
  globalThis.octopus = {
    navigator: shell.navigator,
    routes: routeDefinitions,
    /*
     * At init, we set user data in the window.octopus for easy access inside the code
     * Keep in mind that when JWT refreshes, re-assign this if needs be.
     * ONLY and ONLY access this variable inside logged-in routes
     * If you don't you get an error.
     **/
    user: getLoggedInUser(),
  }

  root.render(
    <React.StrictMode>
      <App>{shell.routerJsx}</App>
    </React.StrictMode>,
  )
}
