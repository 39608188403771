import { formatDateForApi } from '@santsg/common'
import { dayjs } from '@santsg/common'

import { defineRoute } from '@santsg/router'

const routes = {
  /* ======================================================
   * SERVICES > EXCURSION
   * ======================================================
   */

  excursions: defineRoute({
    path: '/excursion/list',
    importer: () => import('@/_excursion_/app/excursions/page'),
  }),

  excursion: defineRoute({
    path: '/excursion/list/:id',
    importer: () => import('@/_excursion_/app/excursions/[id]/layout'),
  }),

  excursionDetails: defineRoute({
    path: '/excursion/list/:id/details',
    importer: () => import('@/_excursion_/app/excursions/[id]/details/page'),
  }),

  excursionMedia: defineRoute({
    path: '/excursion/list/:id/media',
    importer: () => import('@/_excursion_/app/excursions/[id]/media/page'),
  }),

  excursionItinerary: defineRoute({
    path: '/excursion/list/:id/itinerary',
    importer: () => import('@/_excursion_/app/excursions/[id]/itinerary/page'),
  }),

  excursionServiceItemsAndAncillaries: defineRoute({
    path: '/excursion/list/:id/service-items-and-ancillaries',
    importer: () => import('@/_excursion_/app/excursions/[id]/service-items-ancilaries/page'),
  }),

  excursionSalePrices: defineRoute({
    path: '/excursion/list/:id/sale-prices',
    importer: () => import('@/_excursion_/app/excursions/[id]/excursion-price/page'),
  }),

  excursionRestrictions: defineRoute({
    path: '/excursion/list/:id/restrictions',
    importer: () => import('@/_excursion_/app/excursions/[id]/restrictions/page'),
  }),

  excursionWarningMessages: defineRoute({
    path: '/excursion/list/:id/warning-messages',
    importer: () => import('@/_excursion_/app/excursions/[id]/warning-messages/page'),
  }),

  serviceItemsAndAncillaries: defineRoute({
    path: '/excursion/ancillaries',
    importer: () => import('@/_excursion_/app/ancillaries/page'),
  }),

  serviceItemAndAncillary: defineRoute({
    path: '/excursion/service-items-and-ancillaries/:id',
    importer: () => import('@/_excursion_/app/ancillaries/[id]/layout'),
  }),

  serviceItemAndAncillaryDetails: defineRoute({
    path: '/excursion/service-items-and-ancillaries/:id/details',
    importer: () => import('@/_excursion_/app/ancillaries/[id]/detail/page'),
  }),

  serviceItemAndAncillaryPrices: defineRoute({
    path: '/excursion/service-items-and-ancillaries/:id/prices',
    importer: () => import('@/_excursion_/app/ancillaries/[id]/price/page'),
  }),

  excursionPackage: defineRoute({
    path: '/excursion/excursion-package',
    importer: () => import('@/_excursion_/app/excursion-package/page'),
  }),

  excursionPackageCreate: defineRoute({
    path: '/excursion/excursion-package/create',
    importer: () => import('@/_excursion_/app/excursion-package/create/page.tsx'),
  }),

  excursionPackageEdit: defineRoute({
    path: '/excursion/excursion-package/:id/edit',
    importer: () => import('@/_excursion_/app/excursion-package/edit/page.tsx'),
  }),

  infoCocktail: defineRoute({
    initialQueryParams: () => {
      const tomorrow = formatDateForApi(dayjs().add(1, 'day'), true)
      return {
        beginDate: tomorrow,
      }
    },
    path: '/excursion/info-cocktail',
    importer: () => import('@/_excursion_/app/info-cocktail/page'),
  }),

  guideCommissions: defineRoute({
    path: '/excursion/guide-commissions',
    importer: () => import('@/_excursion_/app/guide-commissions/page'),
  }),

  excursionManagementMonitor: defineRoute({
    path: '/excursion/monitor',
    importer: () => import('@/_excursion_/app/management-monitor/page'),
  }),

  /* ======================================================
   * REPORTS > EXCURSION REPORTS
   * ======================================================
   */

  excursionAccountControl: defineRoute({
    path: '/reports/excursion-account-control',
    importer: () => import('@/_excursion_/app/excursion-account-control/page'),
  }),

  /* ======================================================
   * MASTER DATA > EXCURSION DEFINITIONS
   * ======================================================
   */

  excursionVariations: defineRoute({
    path: '/excursion/definition/variations',
    importer: () => import('@/_excursion_/app/variations/page'),
  }),

  excursionFacilities: defineRoute({
    path: '/excursion/definition/facilities',
    importer: () => import('@/_excursion_/app/facilities/page'),
  }),

  excursionThemes: defineRoute({
    path: '/excursion/definition/themes',
    importer: () => import('@/_excursion_/app/themes/page'),
  }),

  guideTypes: defineRoute({
    path: '/excursion/definition/guide-types',
    importer: () => import('@/_excursion_/app/guide-types/page'),
  }),

  introductionHeaders: defineRoute({
    path: '/excursion/definition/introduction-headers',
    importer: () => import('@/_excursion_/app/introduction-headers/page'),
  }),

  guides: defineRoute({
    path: '/excursion/definition/guides',
    importer: () => import('@/_excursion_/app/guides/page'),
  }),

  assignment: defineRoute({
    path: '/excursion/definition/assignment',
    importer: () => import('@/_excursion_/app/assignment/page'),
  }),

  pickupTimes: defineRoute({
    path: '/excursion/definition/pickup-times',
    importer: () => import('@/_excursion_/app/pickup-times/page'),
  }),

  /* ======================================================
   * PRICE SEARCH
   * ======================================================
   */

  excursionPriceSearch: defineRoute({
    path: '/excursion/price-search',
    importer: () => import('@/_excursion_/app/price-search/page'),
  }),
}

export type RouteNameType = keyof typeof routes

Object.keys(routes).forEach((name) => {
  routes[name as RouteNameType].name = name
})

export { routes }
