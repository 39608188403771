import { type RouteDefinition } from '@santsg/router'
import { prepareRoutes } from '@santsg/octopus.shared'

import { type RouteNameType as GuestRouteNameType, routes as auth } from '@/_auth_/routes.def'
import { type RouteNameType as GeneralRouteNameType, routes as main } from '@/_main_/routes.def'
import { type RouteNameType as HotelRouteNameType, routes as hotel } from '@/_hotel_/routes.def'
import { type RouteNameType as ExcursionRouteNameType, routes as excursion } from '@/_excursion_/routes.def'
import { type RouteNameType as TransferRouteNameType, routes as transfer } from '@/_transfer_/routes.def'
import { type RouteNameType as BookingRouteNameType, routes as booking } from '@/_booking_/routes.def'
import { type RouteNameType as FlightRouteNameType, routes as flight } from '@/_flight_/routes.def.ts'

export type RouteNameType =
  | GuestRouteNameType
  | GeneralRouteNameType
  | HotelRouteNameType
  | ExcursionRouteNameType
  | TransferRouteNameType
  | BookingRouteNameType
  | FlightRouteNameType

const routes = {
  ...main,
  ...auth,
  ...hotel,
  ...excursion,
  ...transfer,
  ...booking,
  ...flight,
} as Record<RouteNameType, RouteDefinition>

prepareRoutes(routes)

export { routes }
