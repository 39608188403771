import { defineRoute } from '@santsg/router'

const routes = {
  HOTEL_hotels: defineRoute({
    path: '/hotel/list',
    importer: () => import('./app/hotels/page'),
  }),

  HOTEL_ancillaries: defineRoute({
    path: '/hotel/ancillaries',
    importer: () => import('@/_hotel_/app/ancillaries/page'),
  }),

  HOTEL_supplements: defineRoute({
    path: '/hotel/supplements',
    importer: () => import('@/_hotel_/app/supplements/page'),
  }),

  HOTEL_hotel: defineRoute({
    path: '/hotel/list/:hotelId',
    importer: () => import('./app/hotel/layout'),
  }),

  HOTEL_hotel_details: defineRoute({
    path: '/hotel/list/:hotelId/details',
    importer: () => import('@/_hotel_/app/hotel/children/details/page'),
  }),

  HOTEL_hotel_contacts: defineRoute({
    path: '/hotel/list/:hotelId/contacts',
    importer: () => import('@/_hotel_/app/hotel/children/contacts/page'),
  }),

  HOTEL_hotel_bankAccounts: defineRoute({
    path: '/hotel/list/:hotelId/bank-accounts',
    importer: () => import('@/_hotel_/app/hotel/children/bank-accounts/page'),
  }),

  HOTEL_hotel_mediaSeason: defineRoute({
    path: '/hotel/list/:hotelId/media/seasons/:seasonId',
    importer: () => import('@/_hotel_/app/hotel/children/media-seasons/layout.tsx'),
  }),

  HOTEL_hotel_mediaSeason_multimedia: defineRoute({
    path: '/hotel/list/:hotelId/media/seasons/:seasonId/multimedia',
    importer: () => import('@/_hotel_/app/hotel/children/media-seasons/children/multimedia/page'),
  }),

  HOTEL_hotel_mediaSeason_hotelFacilities: defineRoute({
    path: '/hotel/list/:hotelId/media/seasons/:seasonId/hotel-facilities',
    importer: () => import('@/_hotel_/app/hotel/children/media-seasons/children/hotel-facilities/page'),
  }),

  HOTEL_hotel_mediaSeason_roomFacilities: defineRoute({
    path: '/hotel/list/:hotelId/media/seasons/:seasonId/room-facilities',
    importer: () => import('@/_hotel_/app/hotel/children/media-seasons/children/room-facilities/page'),
  }),

  HOTEL_hotel_mediaSeason_nearbyLocations: defineRoute({
    path: '/hotel/list/:hotelId/media/seasons/:seasonId/nearby-locations',
    importer: () => import('@/_hotel_/app/hotel/children/media-seasons/children/nearby-locations/page'),
  }),

  HOTEL_hotel_messages: defineRoute({
    path: '/hotel/list/:hotelId/messages',
    importer: () => import('@/_hotel_/app/hotel/children/messages/page'),
  }),

  HOTEL_hotel_contracts: defineRoute({
    path: '/hotel/list/:hotelId/contracts',
    importer: () => import('@/_hotel_/app/hotel/children/contracts/page'),
  }),

  /* ======================================================
   * MASTER DATA > HOTEL DEFINITIONS
   * ======================================================
   */

  HOTEL_themes: defineRoute({
    path: '/hotel/definition/themes',
    importer: () => import('@/_hotel_/app/themes/page'),
  }),

  HOTEL_propertyTypes: defineRoute({
    path: '/hotel/definition/property-types',
    importer: () => import('@/_hotel_/app/property-types/page'),
  }),

  HOTEL_hotelCategories: defineRoute({
    path: '/hotel/definition/hotel-categories',
    importer: () => import('@/_hotel_/app/hotel-categories/page'),
  }),

  HOTEL_facilityCategories: defineRoute({
    path: '/hotel/definition/facility-categories',
    importer: () => import('@/_hotel_/app/facility-categories/page'),
  }),

  HOTEL_introductionHeaders: defineRoute({
    path: '/hotel/definition/introduction-headers',
    importer: () => import('@/_hotel_/app/introduction-headers/page'),
  }),

  HOTEL_contractSeasons: defineRoute({
    path: '/hotel/definition/contract-seasons',
    importer: () => import('@/_hotel_/app/contract-seasons/page'),
  }),

  HOTEL_roomViewTypes: defineRoute({
    path: '/hotel/definition/room-view-types',
    importer: () => import('@/_hotel_/app/room-view-types/page'),
  }),

  HOTEL_roomTypes: defineRoute({
    path: '/hotel/definition/room-types',
    importer: () => import('@/_hotel_/app/room-types/page'),
  }),

  HOTEL_boardTypes: defineRoute({
    path: '/hotel/definition/board-types',
    importer: () => import('@/_hotel_/app/board-types/page'),
  }),

  HOTEL_facilities: defineRoute({
    path: '/hotel/definition/facilities',
    importer: () => import('@/_hotel_/app/facilities/page'),
  }),

  HOTEL_roomFacilities: defineRoute({
    path: '/hotel/definition/room-facilities',
    importer: () => import('@/_hotel_/app/room-facilities/page'),
  }),
}

export type RouteNameType = keyof typeof routes

Object.keys(routes).forEach((name) => {
  routes[name as RouteNameType].name = name
})

export { routes }
