import { defineRoute } from '@santsg/router'

const routes = {
  login: defineRoute({
    path: '/login',
    importer: () => import('@/_auth_/app/login/page'),
  }),

  register: defineRoute({
    path: '/register',
    importer: () => import('@/_auth_/app/register/page'),
  }),

  resetPassword: defineRoute({
    path: '/reset-password',
    importer: () => import('@/_auth_/app/reset-password/page'),
  }),
}

export type RouteNameType = keyof typeof routes

Object.keys(routes).forEach((name) => {
  routes[name as RouteNameType].name = name
})

export { routes }
