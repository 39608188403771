import React from 'react'
import { Root } from '@santsg/octopus.shared'
import { useRegisterMainMenu } from './menu'

type Props = {
  children: React.ReactNode
}

export default function App(props: Props) {
  const { children } = props

  useRegisterMainMenu()

  return <Root>{children}</Root>
}
