import type { RouteConfigType } from '@santsg/router'
import { routes as defs } from './routes.def'

export const routes: RouteConfigType[] = [
  defs.flights,
  {
    ...defs.flight,
    children: [defs.flightDetails, defs.flightDays],
  },

  // MASTER DATA > FLIGHT DEFINITIONS
  defs.airports,
]
