import { useMemo } from 'react'

import { routes } from './routes.def'

export default function useMainMenuItems() {
  const t = useTranslationNs('LAYOUT')

  return useMemo(
    () => [
      {
        parent: 'services',
        items: [
          {
            label: t('dashboard_menu__hotel', 'Hotel'),
            children: [
              {
                label: t('dashboard_menu__hotels_list', 'Hotels List'),
                route: routes.HOTEL_hotels,
              },
              {
                label: t('dashboard_menu__hotel_ancillaries', 'Hotel Ancillaries'),
                route: routes.HOTEL_ancillaries,
              },
              {
                label: t('dashboard_menu__hotel_supplements', 'Supplements'),
                route: routes.HOTEL_supplements,
              },
            ],
          },
        ],
      },

      {
        parent: 'master-data',
        items: [
          {
            label: t('dashboard_menu__hotel_definitions', 'Hotel Definitions'),
            children: [
              {
                label: t('dashboard_menu__hotel_property_types', 'Property Types'),
                route: routes.HOTEL_propertyTypes,
              },
              {
                label: t('dashboard_menu__hotel_categories', 'Hotel Categories'),
                route: routes.HOTEL_hotelCategories,
              },
              {
                label: t('dashboard_menu__hotel_themes', 'Hotel Themes'),
                route: routes.HOTEL_themes,
              },
              {
                label: t('dashboard_menu__hotel_introduction_headers', 'Introduction Headers'),
                route: routes.HOTEL_introductionHeaders,
              },
              {
                label: t('dashboard_menu__hotel_contract_seasons', 'Contract Seasons'),
                route: routes.HOTEL_contractSeasons,
              },
              {
                label: t('dashboard_menu__facility_categories', 'Facility Categories'),
                route: routes.HOTEL_facilityCategories,
              },
              {
                label: t('dashboard_menu__hotel_facilities_types', 'Hotel Facilities'),
                route: routes.HOTEL_facilities,
              },
              {
                label: t('dashboard_menu__hotel_room_types', 'Room Types'),
                route: routes.HOTEL_roomTypes,
              },
              {
                label: t('dashboard_menu__hotel_room_view_types', 'Room View Types'),
                route: routes.HOTEL_roomViewTypes,
              },
              {
                label: t('dashboard_menu__hotel_room_facilities_types', 'Room Facilities'),
                route: routes.HOTEL_roomFacilities,
              },
              {
                label: t('dashboard_menu__hotel_board_types', 'Board Types'),
                route: routes.HOTEL_boardTypes,
              },
            ],
          },
        ],
      },
    ],
    [t],
  )
}
