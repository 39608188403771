import * as Sentry from '@sentry/react'
import { getSession } from '@santsg/common'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://3f370d6d8aea6dc788cc768df49c8e15@o4508453941739520.ingest.de.sentry.io/4508453944688720',
    environment: 'dev',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: [/^https:\/\/dev\.sanoctopus\.com/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })

  const user = getSession()?.user

  if (user) {
    Sentry.setUser({
      id: user.userId,
      email: user.email,
      username: user.email,
    })
  } else {
    Sentry.setUser(null)
  }

  console.info('OCTOPUS', 'sentry configuration done!')
}
