import { getSession } from '@santsg/common'
import { Navigate } from '@santsg/router'

export default function HomePage() {
  const session = getSession()

  if (session) {
    return <Navigate to="/dashboard" />
  } else {
    return <Navigate to="/login" />
  }
}
