import { useMemo } from 'react'
import { getSession } from '@santsg/common'
import { LayoutOutlined } from '@santsg/octopus.icons'

import { routes } from './routes.def'

export default function useMainMenuItems() {
  const t = useTranslationNs('LAYOUT')

  const user = getSession()?.user

  return useMemo(
    () => [
      {
        parent: 'root',
        items: [
          {
            icon: <LayoutOutlined />,
            label: t('dashboard_menu__dashboard', 'Dashboard'),
            route: routes.dashboard,
          },
        ],
      },
      {
        parent: 'bookings',
        items: [
          {
            label: t('dashboard_menu__booking_import', 'Booking Import'),
            route: routes.bookingImport,
            order: 500,
          },
        ],
      },
      {
        parent: 'actors',
        items: [
          {
            label: t('dashboard_menu__buyers', 'Buyers'),
            route: routes.buyers,
          },
          {
            label: t('dashboard_menu__suppliers', 'Suppliers'),
            route: routes.suppliers,
          },
          {
            label: t('dashboard_menu__users', 'Users'),
            route: routes.users,
          },
          {
            label: t('dashboard_menu__invited_users', 'Invited Users'),
            route: routes.invitedUsers,
          },
          {
            label: t('dashboard_menu__companies', 'Companies'),
            hidden: user?.companyId === user?.organizationId,
            route: routes.companies,
          },
          {
            label: t('dashboard_menu__organizations', 'Organizations'),
            hidden: user?.companyId === user?.organizationId,
            route: routes.organizations,
          },
        ],
      },

      {
        parent: 'accounting',
        items: [
          {
            label: t('dashboard_menu__company_accounts', 'Company Accounts'),
            route: routes.companyAccounts,
          },
          {
            label: t('dashboard_menu__custom_currency_rates', 'Custom Currency Rates'),
            route: routes.customCurrencyRates,
          },
          {
            label: t('dashboard_menu__provider_rates', 'Provider Rates'),
            route: routes.providerRates,
          },
          {
            label: t('dashboard_menu__guide_financial', 'Guide Financial'),
            route: routes.guideFinancial,
          },
          {
            label: t('dashboard_menu__supplier_financial', 'Supplier Financial'),
            route: routes.supplierFinancial,
          },
        ],
      },

      {
        parent: 'master-data',
        items: [
          {
            label: t('dashboard_menu__common_used', 'Common Used'),
            children: [
              {
                label: t('dashboard_menu__markets', 'Markets'),
                route: routes.markets,
                order: 200,
              },
              {
                label: t('dashboard_menu__mapping', 'Mapping'),
                route: routes.mapping,
                order: 300,
              },
            ],
          },
        ],
      },

      {
        parent: 'setting',
        items: [
          {
            label: t('dashboard_menu__roles_and_permissions', 'Roles & Permissions'),
            route: routes.roles,
          },
          {
            label: t('dashboard_menu__localization', 'Localization'),
            route: routes.localizations,
          },
        ],
      },
    ],
    [t, user],
  )
}
