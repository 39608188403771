import { useMemo } from 'react'

import { routes } from './routes.def'

export default function useMainMenuItems() {
  const t = useTranslationNs('LAYOUT')

  return useMemo(
    () => [
      {
        parent: 'price-search',
        items: [
          {
            label: t('dashboard_menu__transfer_price_search', 'Transfer Price Search'),
            route: routes.transferPriceSearch,
          },
        ],
      },

      {
        parent: 'bookings',
        items: [
          {
            label: t('dashboard_menu__vehicle_operations', 'Vehicle Operations'),
            route: routes.vehicleOperations,
            order: 200,
          },
          {
            label: t('dashboard_menu__vehicle_management', 'Vehicle Management'),
            route: routes.vehicleManagement,
            order: 250,
          },
        ],
      },

      {
        parent: 'services',
        items: [
          {
            label: t('dashboard_menu__transfer', 'Transfer'),
            children: [
              {
                label: t('dashboard_menu__transfers_list', 'Transfers List'),
                route: routes.transfers,
              },
              {
                label: t('dashboard_menu__ancillaries', 'Transfers Ancillaries'),
                route: routes.transferAncillaries,
              },
            ],
          },
        ],
      },

      {
        parent: 'master-data',
        items: [
          {
            label: t('dashboard_menu__transfer_definitions', 'Transfer Definitions'),
            children: [
              {
                label: t('dashboard_menu__operational_region_groups', 'Operational Region Groups'),
                route: routes.operationalRegionGroups,
              },
              {
                label: t('dashboard_menu__drivers', 'Drivers'),
                route: routes.drivers,
              },
              {
                label: t('dashboard_menu__introduction_headers', 'Introduction Headers'),
                route: routes.transferIntroductionHeaders,
              },
              {
                label: t('dashboard_menu__vehicle_types', 'Vehicle Types'),
                route: routes.vehicleTypes,
              },
              {
                label: t('dashboard_menu__vehicles', 'Vehicles'),
                route: routes.vehicles,
              },
              {
                label: t('dashboard_menu__transfer_cost_items', 'Transfer Cost Items'),
                route: routes.transferCostItems,
              },
              {
                label: t('dashboard_menu__transfer_types', 'Transfer Types'),
                route: routes.transferTypes,
              },
            ],
          },
        ],
      },
    ],
    [t],
  )
}
