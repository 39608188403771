// First initialize Sentry for crash reporting
import './sentry'

// First import config files
import '@/shell/configure.env'
import '@/shell/configure.api'

// Then import renderOctopus function
import renderOctopus from '@/shell/render'

// Now render octopus app
renderOctopus().then(() => {
  console.info('OCTOPUS', 'initial render done!')
})
