import type { RouteConfigType } from '@santsg/router'
import { routes as defs } from './routes.def'

export const routes: RouteConfigType[] = [
  // HOME & DASHBOARD
  defs.home,
  defs.dashboard,

  // BOOKINGS
  defs.bookingImport,
  {
    ...defs.mapping,
    children: [defs.mappingBuyer, defs.mappingTransferType, defs.mappingHotel, defs.mappingFromToLocation],
  },

  // ACTORS

  // ACTORS > BUYERS
  defs.buyers,
  {
    ...defs.buyer,
    children: [defs.buyerDetails, defs.buyerUsers, defs.buyerInviteUsers],
  },

  // ACTORS > SUPPLIERS
  defs.suppliers,
  {
    ...defs.supplier,
    children: [defs.supplierDetails, defs.supplierPrices],
  },

  // ACTORS > USERS
  defs.users,

  // ACTORS > INVITED USERS
  defs.invitedUsers,

  // ACTORS > COMPANY
  defs.companies,
  {
    ...defs.company,
    children: [defs.companyDetails, defs.companyUsers, defs.companyInvitedUsers],
  },

  // ACTORS > ORGANIZATIONS
  defs.organizations,
  {
    ...defs.organization,
    children: [defs.organizationDetails, defs.organizationUsers, defs.organizationInviteUsers],
  },

  // MASTER DATA

  // MASTER DATA > COMMON USED
  defs.markets,

  // ACCOUNTING
  defs.companyAccounts,
  defs.customCurrencyRates,
  defs.providerRates,
  defs.guideFinancial,
  defs.supplierFinancial,
  defs.supplierFinancialInvoiceDetails,
  defs.supplierFinancialPaymentDetails,
  {
    ...defs.guideFinancialDetail,
    children: [defs.guideFinancialDetailCollection, defs.guideFinancialDetailCommission, defs.guideFinancialDetailFinancials],
  },
  {
    ...defs.supplierFinancialDetail,
    children: [defs.supplierFinancialDetailBookings, defs.supplierFinancialDetailInvoices, defs.supplierFinancialDetailPayments],
  },

  // SETTING
  {
    ...defs.settings,
    children: [
      defs.bookingSetting,
      defs.currencySetting,
      defs.localizationSetting,
      defs.integrationSetting,
      defs.notificationSetting,
      defs.smtpSetting,
      defs.dateTimeSetting,
    ],
  },
  defs.roles,
  defs.rolePermissions, // @TODO: Fix naming (permissions of a role)
  defs.localizations,

  // OTHER
  defs.userProfile,
  defs.organizationProfile,
]
