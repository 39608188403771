import { useMemo } from 'react'

import { routes } from './routes.def'

export default function useMainMenuItems() {
  const t = useTranslationNs('LAYOUT')

  return useMemo(
    () => [
      {
        parent: 'bookings',
        items: [
          {
            label: t('dashboard_menu__booking_monitor', 'Booking Monitor'),
            route: routes.bookingMonitor,
            order: 150,
          },
        ],
      },
    ],
    [t],
  )
}
