import { useMemo } from 'react'
import { routes } from './routes.def'

export default function useMainMenuItems() {
  const t = useTranslationNs('LAYOUT')

  return useMemo(
    () => [
      {
        parent: 'services',
        items: [
          {
            label: t('dashboard_menu__flight', 'Flight'),
            children: [
              {
                label: t('dashboard_menu__flight_list', 'Flight List'),
                route: routes.flights,
              },
            ],
          },
        ],
      },

      {
        parent: 'master-data',
        items: [
          {
            label: t('dashboard_menu__flight_definitions', 'Flight Definitions'),
            children: [
              {
                label: t('dashboard_menu__airport_terminals_types', 'Airport Terminals'),
                route: routes.airports,
              },
            ],
          },
        ],
      },
    ],
    [t],
  )
}
